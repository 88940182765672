import React from "react";
import { connect } from "react-redux";
import dt_title from "../../../../../../assets/games/dragon_tiger/dt_title.gif";
import balanceImg from "../../../../../../assets/games/dragon_tiger/DT_Balance.png";
import winAmtImg from "../../../../../../assets/games/dragon_tiger/DT_Winner.png";
import Betpanel from "./betpanel";
import { gameAssets } from './betpanelimgs';
import chips from "../../../timer/dragon_tiger_timer/UI/dt_chips";
// import chips from '../../../chips';
import star_1 from "../../../../../../assets/games/dragon_tiger/star_1.svg";
import star_2 from "../../../../../../assets/games/dragon_tiger/star_2.svg";
import chipSelSound from "../../../../../../assets/sounds/take.mp3";
import clearSoundFile from "../../../../../../assets/sounds/clear.mp3";
import betSoundFile from "../../../../../../assets/sounds/bet.mp3";
import tickSound from "../../../../../../assets/sounds/tick.mp3";

class GameBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      isTimeSent: false,
      getTime: 30,
      timeTxt: 0,
    }
    this.timeNum = 0;
    this.Betpanel = React.createRef();
    this.tickSnd = new Audio(tickSound);
    this.dtChipStock = [
      { src: "1", key: 1, val: 10 },
      { src: "2", key: 1, val: 25 },
      { src: "3", key: 1, val: 50 },
      { src: "4", key: 1, val: 100 },
      { src: "5", key: 1, val: 500 },
      { src: "6", key: 1, val: 1000 },
      { src: "7", key: 1, val: 5000 },
      { src: "8", key: 1, val: 10000 },
    ];
    this.chipsArray = this.dtChipStock;
    this.histArr = [[, , , ,], [, , , ,], [, , , ,], [, , , ,], [, , , ,]];


  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    if (document.getElementById("betBoxCover")) {
      document.getElementById("betBoxCover").style.display = "block";
      document.getElementById("betBoxCover").style.zIndex = 300;
    }

    for (let m = 0; m < this.dtChipStock.length; m++) {
      this.dtChipStock[m].src = chips[m].src;
      // this.checkChipValue(Number(this.dtChipStock[m].val), m);
    }

       if (this.props.dtt_chipValues) {
      let newChips = this.props.dtt_chipValues.split(",");
      console.log(newChips);
      console.log(newChips.length)
      let lblTxt = "";

      for (let i = 0; i < newChips.length; i++) {
      // for (let i = 0; i < this.chipsArray.length; i++) {
        if (Number(newChips[i]) >= 1000 && Number(newChips[i]) <= 999999) {
          lblTxt = "K"
        } else if (Number(newChips[i]) >= 1000000 && Number(newChips[i]) <= 999999999) {
          lblTxt = "M"
        } else if (Number(newChips[i]) > 999999999) {
          lblTxt = "B"
        }
        console.log(newChips[i])
        this.chipsArray[i].val = Number(newChips[i])
        this.chipsArray[i].label = newChips[i]
       
        // this.chipsArray[i].label=this.changeAmtlabl(this.chipVal[i].val)
        
    
      
        // this.checkChipValue(Number(this.chipVal[i].val), lblTxt, i);            
      }


      // this.setState({ chipSelect: Number(this.chipVal[0].val) });
      // this.state.chipSelect = Number(this.chipVal[0].val);
      // console.log(this.chipVal[0].val);
      // console.log(this.state.chipSelect);
    }

    console.log(this.dtChipStock);
    this.props.chipValue(this.dtChipStock[0].val);

    this.clearSound = new Audio(clearSoundFile);
    this.betSound = new Audio(betSoundFile);
    this.takeBtnSnd = new Audio(chipSelSound);

    document.getElementById("dt_timeSpan").style.display = "none";

    var dragonWinCard = document.getElementById('dragonWinCard');
    var tigerWinCard = document.getElementById('tigerWinCard');
    if (dragonWinCard) {
      dragonWinCard.innerHTML = ``;
    }
    if (tigerWinCard) {
      tigerWinCard.innerHTML = ``;
    }
    this.updateHistory(this.props.dragonTigerHistoy)

    this.timerclear = setInterval(this.myTimer, 1000);

  }

  updateHistory(hist) {
    this.history = hist;
    console.log(this.history);
    // alert('ding')
    if (this.history && this.history.length > 0) {
      for (let i = 0; i < this.history.length; i++) {
        let resultsVal = JSON.parse(this.history[i].results);
        console.log(resultsVal);
        if (resultsVal[0] == 0) {
          this.histArr[i][0] = star_2;
        } else {
          this.histArr[i][0] = star_1;
        }

        if (resultsVal[1] == 1) {
          this.histArr[i][1] = "S";
        } else if (resultsVal[1] == 2) {
          this.histArr[i][1] = "B";
        } else {
          this.histArr[i][1] = "7";
        }

        if (resultsVal[2] == 0) {
          this.histArr[i][2] = star_2;
        } else {
          this.histArr[i][2] = star_1;
        }

        if (resultsVal[3] == 0) {
          this.histArr[i][3] = star_2;
        } else {
          this.histArr[i][3] = star_1;
        }

        if (resultsVal[4] == 1) {
          this.histArr[i][4] = "S";
        } else if (resultsVal[4] == 2) {
          this.histArr[i][4] = "B";
        } else {
          this.histArr[i][4] = "7";
        }
      }
    }

  }

  sendTimer(dt_time) {
    if (document.getElementById("dt_timeSpan")) {
      document.getElementById("dt_timeSpan").style.display = "block";
    }
    if (dt_time != NaN) {
      console.log("DT: "+dt_time);
      this.setState({ getTime: dt_time });
      this.myTimer();
    }
  }

  getTimer() {
    if (this.props.isGameActive) {
      const body = {
        sessionId: sessionStorage.getItem('sessionId'),
        CN: "GET_GAME_TIMER",
        object: {
          gameId: this.props.gameId,
          playerId: Number(this.props.playerId),
        },
      };
      this.props.network.sendof(body);
      console.log(body)
    }
  }

  checkChipValue(chipValue, chpTxt, i) {
    if (chipValue >= 1000) {
      chipValue = chipValue / 1000;
      this.checkChipValue(chipValue, chpTxt, i)
    } else {
      // console.log(chpTxt);
      this.showResultChipValue(chipValue, chpTxt, i);
      return chipValue
    }
  }

  showResultChipValue(chVal, chipTxt, index) {
    // console.log(chVal);
    // console.log(chVal + "" + chipTxt)
    // this.dtChipStock[index].key = chVal + "" + chipTxt;
  }

  getBetBoxId(id) {
    // alert(id);
    this.props.getBetBoxId(id);
  }

  stopBetSound = () => {
    this.props.stopBetSound();
  }

  clearBtn_fn() {
    this.clearSound.play();
    this.props.cleareBets();
  }

  ChipArrow(mode) {
    console.log(this.state.count);
    this.takeBtnSnd = new Audio(chipSelSound);
    this.takeBtnSnd.play()

    if (document.getElementById("chip_Dec").classList.contains("disBtn")) {
      document.getElementById("chip_Dec").classList.remove("disBtn");
    }
    if (document.getElementById("chip_Inc").classList.contains("disBtn")) {
      document.getElementById("chip_Inc").classList.remove("disBtn");
    }

    if (mode == "dec") {
      if (this.state.count > 0) {
        this.setState({
          count: this.state.count - 1
        })
        this.props.chipValue(this.chipsArray[this.state.count - 1].val);
        document.getElementById("chipImg").src = this.chipsArray[this.state.count - 1].src
        document.getElementById("chipNumber").textContent = this.chipsArray[this.state.count - 1].val
      }
      if (this.state.count == 1) {
        document.getElementById("chip_Dec").classList.add("disBtn");
      }
    } else {
      console.log(this.props.maxBetAmt);
      console.log(this.chipsArray[this.state.count + 1].val);
      console.log(this.chipsArray[this.state.count + 1].val <= this.props.maxBetAmt);
      if (this.chipsArray[this.state.count + 1].val <= this.props.maxBetAmt) {
        console.log(this.state.count);
        if (this.state.count <= 5) {
          this.setState({
            count: this.state.count + 1
          })
          // this.props.chipValue(this.dtChipStock[0].val);
          this.props.chipValue(this.chipsArray[this.state.count + 1].val);
          document.getElementById("chipImg").src = this.chipsArray[this.state.count + 1].src
          document.getElementById("chipNumber").textContent = this.chipsArray[this.state.count + 1].val
        }
        if (this.chipsArray[this.state.count + 1].val == this.props.maxBetAmt) {
          document.getElementById("chip_Inc").classList.add("disBtn");
        }
      } else {
        document.getElementById("chip_Inc").classList.add("disBtn");
      }
      if (this.state.count == 5) {
        document.getElementById("chip_Inc").classList.add("disBtn");
      }
    }
  }


  btn_fn(action) {
    this.betSound = new Audio(betSoundFile);
    switch (action) {
      case "newGame":
        this.betSound.play();
        this.props.newGame();
        break;
      case "undo":
        this.betSound.play();
        this.props.undoFun();
        break;
      case "deal":
        console.log("----------->>>   " + this.props.betBtnStatus);
        if (this.props.betBtnStatus == 0) {
          this.betSound.play();
          this.props.rebetFun();
        } else if (this.props.betBtnStatus == 1) {
          this.betSound.play();
          this.props.deal();
        } else if (this.props.betBtnStatus == 2) {
          this.takeBtnSnd.play()
          this.props.takeAmount_fn();
        }
        break;
      case "double":
        this.betSound.play();
        this.props.doubleBetFun();
        break;
      default:
        break;
    }
  }
  componentWillUnmount() {
    this.clearTimer_fn();
  }

  clearTimer_fn() {
    console.log("Clear DragonTiger Game Interval");
    clearInterval(this.timerclear);
    if (this.tickSnd) {
      if (document.getElementById('iospopup').style.display == "none") {
        this.tickSnd.pause();
        this.tickSnd = null
      }      
    }
    this.setState({ getTime: null });

    this.Betpanel.clearMouseDown();


  }

  myTimer = () => {
    // console.log(this.state.getTime)
    if (this.state.getTime != null && this.state.getTime != NaN && this.state.getTime >= 0) {
      if (document.getElementById("dt_timerGlow")) {
        document.getElementById("dt_timerGlow").classList.add("dt_timer_glow");
      }
      // console.log("DT: GameBox Time: "+this.state.getTime)
      let d = this.state.getTime;
      let n = --d;
      //console.log("DT: GameBox Time: "+this.state.getTime+" ---   "+d+" -----  "+n)

      let sec = parseInt(n % 60);
      let secS = sec.toString();
      this.setState({ getTime: n });
      if (this.state.getTime != null) {

        if (String(sec).length < 2) {
          secS = "0" + sec.toString();
        }
        //console.log("DT: GameBox: "+secS)
        //console.log(sec)

        this.setState({ timeTxt: secS })

        if (n != null && n != NaN && this.props.isGameActive) {         
          if (n == 10 && !this.state.isTimeSent) {
            this.setState({isTimeSent: true})
            this.getTimer();
          }
          if (n <= 0) {
            if (this.props.isGameActive) {
              this.setState({isTimeSent: false , getTime:30 });              
            }
          }          

          if (n == 5) {
            if (this.Betpanel) {
              this.Betpanel.clearMouseDown();
            }
            this.props.removeGlow();
            this.props.disableBtns();
            // console.log("-------------------------------- ////////////////////////////// *----------------------------------------------")
            // console.log(this.props.betSent)
            // console.log(this.props.isGameActive)    
            // console.log("-------------------------------- ////////////////////////////// *----------------------------------------------")
            if (!this.props.betSent && this.props.isGameActive) {
              console.log("DT Timer Bet Sent");
              this.props.deal();
            }
          }

          if (n <= 5 && n >= 0) {
            // console.log(n)
            this.props.disableBtns();
            if (document.getElementById("betBoxCover") && document.getElementById("betBoxCover").style != null && document.getElementById("betBoxCover").style.display != "block") {
              document.getElementById("betBoxCover").style.zIndex = 300;
              document.getElementById("betBoxCover").style.display = 'block';
            }
            if (document.getElementById("dealBtn")) {
              document.getElementById("dealBtn").classList.add("disBtn");
            }
            let disBtn = document.getElementById("reBetBtn");
            if (disBtn) {
              disBtn.classList.add("disBtn");
              disBtn.disabled = true;
            }
          }

          if (n > 5 && n < 27) {
            if (this.props.winCover == false) {
              //console.log(",,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,5")
              if (document.getElementById("betBoxCover")) {
                document.getElementById("betBoxCover").style.display = 'none';
              }
            } else {
              // console.log(",,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,timr"+this.props.winCover)
              if (document.getElementById("betBoxCover")) {
                document.getElementById("betBoxCover").style.display = 'block';
              }
              //this.props.action("enableBtns"); 
            }
          }
          if (n <= 10 && n > 5) {
            if (document.getElementById("dt_timerGlow") && !document.getElementById("dt_timerGlow").classList.contains("dt_timer_glow")) {
              document.getElementById("dt_timerGlow").classList.add("dt_timer_glow");
            }
            if (this.props.isGameActive) {
              if (document.getElementById('iospopup').style.display == "none") {
                this.tickSnd.play();
              }              
            } else {
              this.clearTimer_fn();
            }
          } else {
            if (document.getElementById("dt_timerGlow") && document.getElementById("dt_timerGlow").classList.contains("dt_timer_glow")) {
              document.getElementById("dt_timerGlow").classList.remove("dt_timer_glow");
            }
          }
        }
      } else {
        this.clearTimer_fn();
      }
    }
  }
   topLable_fn(Value) {
    let labelValue = Math.abs(Number(Value))
    // Nine Zeroes for Billions
    return labelValue >= 1.0e+9
  
    ? Number((labelValue / 1.0e+9).toFixed(2)) + "B"
    // Six Zeroes for Millions 
    : labelValue >= 1.0e+6
  
    ? Number((labelValue / 1.0e+6).toFixed(2)) + "M"
    // Three Zeroes for Thousands
    : labelValue >= 1.0e+3
  
    ? Number((labelValue / 1.0e+3).toFixed(2)) + "K"
  
    : Number(labelValue.toFixed(2));
  }

  render() {
    
    // let updatebal = this.topLable_fn(Number(this.props.balancedragon)).toFixed(2);
    // let updatebal = this.topLable_fn(this.props.balancedragon)
    // let updateBet = this.topLable_fn(this.props.betAmount);


    let updatebal = Number(this.props.balancedragon.toFixed(2))
    let updateBet = Number(this.props.betAmount.toFixed(2));

    let updateWin = this.topLable_fn(Math.floor(this.props.winAmount));
    // let updateBet = this.topLable_fn(Math.floor(this.props.betAmount));

    return (
      <div className="middleDrag">
        <div className="dtBox">
          <div className="betBoxCover" id="betBoxCover"></div>
          {/* <img className="dt_board" src={dt_board} /> */}
          <div className="boardUpBox">
            <div className="fd">
              <div className="topHistoryBox">

                <div className="fd">
                  <div className="row">
                    <div className="col-3">
                      <div className="pid_val">
                        ID: {this.props.handId} <br />
                        P ID: {this.props.playerId}<br />
                        {this.props.user}
                      </div>
                      <div className="row balance_lbl">
                        <img className="balace_img" src={balanceImg} />
                        <span className="clr_ff balace_txt" >
                          {updatebal}
                        </span>
                      </div>

                    </div>
                    <div className="col-6">
                      <img className="dt_title" src={dt_title} />
                    </div>
                    <div className="col-3">
                      <div className="row user_name">
                        <img className="winner_img" src={winAmtImg} />
                        <span className="clr_ff win_txt">
                          {updateWin}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row cardWinBox fd">
                <div className="col-4 dragonWinImg">
                  <span className="dragonWinCard" id="dragonWinCard"></span>
                </div>

                <div className="col-4" id="betCards_top">

                  <div className="historyTop d-flex ">
                    <div className="hitory_panel">
                      <table>
                        <tbody>
                          <tr>
                            <th colSpan="2"> Dragon </th>
                            <th> Tie </th>
                            <th colSpan="2"> Tiger </th>
                          </tr>
                          <tr>
                            <td> <img className="histImg" src={this.histArr[0][0]} /> </td>
                            <td> {this.histArr[0][1]} </td>
                            <td> <img className="histImg" src={this.histArr[0][2]} /> </td>
                            <td> <img className="histImg" src={this.histArr[0][3]} /> </td>
                            <td> {this.histArr[0][4]} </td>
                          </tr>
                          <tr>
                            <td> <img className="histImg" src={this.histArr[1][0]} /> </td>
                            <td> {this.histArr[1][1]} </td>
                            <td> <img className="histImg" src={this.histArr[1][2]} /> </td>
                            <td> <img className="histImg" src={this.histArr[1][3]} /> </td>
                            <td> {this.histArr[1][4]} </td>
                          </tr>
                          <tr>
                            <td> <img className="histImg" src={this.histArr[2][0]} /> </td>
                            <td> {this.histArr[2][1]} </td>
                            <td> <img className="histImg" src={this.histArr[2][2]} /> </td>
                            <td> <img className="histImg" src={this.histArr[2][3]} /> </td>
                            <td> {this.histArr[2][4]} </td>
                          </tr>
                          <tr>
                            <td> <img className="histImg" src={this.histArr[3][0]} /> </td>
                            <td> {this.histArr[3][1]} </td>
                            <td> <img className="histImg" src={this.histArr[3][2]} /> </td>
                            <td> <img className="histImg" src={this.histArr[3][3]} /> </td>
                            <td> {this.histArr[3][4]} </td>
                          </tr>
                          <tr>
                            <td> <img className="histImg" src={this.histArr[4][0]} /> </td>
                            <td> {this.histArr[4][1]} </td>
                            <td> <img className="histImg" src={this.histArr[4][2]} /> </td>
                            <td> <img className="histImg" src={this.histArr[4][3]} /> </td>
                            <td> {this.histArr[4][4]} </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>

                <div className="col-4 tigerWinImg" >
                  <span className="dragonWinCard" id="tigerWinCard"></span>
                </div>

              </div>
              <Betpanel
                ref={Betpanel}
                childRef={ref => (this.Betpanel = ref)}
                getBetBoxId={this.getBetBoxId.bind(this)}
                stopBetSound={this.stopBetSound}
                isMobile={this.props.isMobile}
                language={this.props.language}
                minBetAmt={this.props.minBetAmt}
                maxBetAmt={this.props.maxBetAmt}
              />
            </div>
          </div>
          <div className="row rightDrag" >
            <div className="col-4 ">
              <div className=" leftBtns">
                {/* <button id="unDoBtn" onClick={() => btn_fn("undo")} className= "undoBtn dealBtn == true ? 'show dealBtn' : 'show' m_t_15 dr_bt_undo "> */}

                <button
                  id="clrBtn"
                  className={(this.props.isMobile) ? "cursor_none newGameBtn dealBtn dr_bt_undo" : " newGameBtn dealBtn m_t_15 dr_bt_undo"}

                  onClick={(e) => {
                    e.preventDefault();
                    this.clearBtn_fn();
                  }}

                  onPointerDown={(e) => {
                    e.preventDefault();
                    document.getElementById("clrBtn").style.transform = "scale(0.85)";
                    //            
                  }}
                  onPointerOut={(e) => {
                    e.preventDefault();
                    document.getElementById("clrBtn").style.transform = "scale(1)";
                  }}
                  onPointerUp={(e) => {
                    e.preventDefault();
                    document.getElementById("clrBtn").style.transform = "scale(1)";
                  }}
                >
                  <span className="clrBtnTxt">{this.props.language.Clear}</span>
                </button>


                <button id="unDoBtn" onClick={() => this.btn_fn("undo")} className={(this.props.isMobile) ? "cursor_none undoBtn show dealBtn dr_bt_undo" : "undoBtn show dealBtn m_t_15 dr_bt_undo "}
                  onPointerDown={(e) => {
                    e.preventDefault();
                    document.getElementById("unDoBtn").style.transform = "scale(0.85)";
                  }}
                  onPointerOut={(e) => {
                    e.preventDefault();
                    document.getElementById("unDoBtn").style.transform = "scale(1)";
                  }}
                  onPointerUp={(e) => {
                    e.preventDefault();
                    document.getElementById("unDoBtn").style.transform = "scale(1)";
                  }}
                >
                  <span className="clrBtnTxt">{this.props.language.Undo}</span>
                </button>
              </div>
            </div>
            <div className="col-4 ">
              <div className="bottom_images">
                <div className="">
                  <div className="dt_chipBox">
                    <div className={(this.props.isMobile) ? "leftChip disBtn emptyPointer" : "leftChip disBtn addCursorPointer"} id="chip_Dec"
                      onClick={(e) => {
                        e.preventDefault();
                        this.ChipArrow("dec")
                      }}
                      onPointerDown={(e) => {
                        e.preventDefault();
                        document.getElementById("chip_Dec").style.transform = "scale(0.85)";
                      }}
                      onPointerOut={(e) => {
                        e.preventDefault();
                        document.getElementById("chip_Dec").style.transform = "scale(1)";
                      }}
                      onPointerUp={(e) => {
                        e.preventDefault();
                        document.getElementById("chip_Dec").style.transform = "scale(1)";
                      }}
                    >
                      {/* <button className={(this.props.isMobile) ? "emptyPointer" : "addCursorPointer"}
                          // disabled={this.state.count != 0 ? false : true}
                          
                        > */}
                      <img src={gameAssets[0].src} />
                      {/* </button> */}
                    </div>
                    <div className="dt_Chips">
                      {/* <span className="chipValue" id="chipVal">{this.props.selectedChipVal}</span> */}
                      <img className="pointer" id="chipImg" src={this.chipsArray[0].src} />
                      <span id="chipNumber">{this.chipsArray[0].val}</span>
                    </div>
                    <div className={(this.props.isMobile) ? "rightChip emptyPointer" : "rightChip addCursorPointer"} id="chip_Inc"
                      onClick={(e) => {
                        e.preventDefault();
                        this.ChipArrow("inc")
                      }}
                      onPointerDown={(e) => {
                        e.preventDefault();
                        document.getElementById("chip_Inc").style.transform = "scale(0.85)";
                      }}
                      onPointerOut={(e) => {
                        e.preventDefault();
                        document.getElementById("chip_Inc").style.transform = "scale(1)";
                      }}
                      onPointerUp={(e) => {
                        e.preventDefault();
                        document.getElementById("chip_Inc").style.transform = "scale(1)";
                      }}
                    >
                      {/* <button className={(this.props.isMobile) ? "emptyPointer" : "addCursorPointer"}
                        
                          // disabled={this.state.count != 8 ? false : true}
                         
                        > */}
                      <img src={gameAssets[1].src}

                      />
                      {/* </button> */}
                    </div>
                  </div>

                  <div className="centered_betTxt row">
                    <span className="clr_ff bet_txt" id="betAmt">
                      {this.props.language.Bet + ": "} {updateBet}
                    </span>
                  </div>
                  <div className="dt_infoTxt row">
                    <span id="info_txt" className=" clr_ff ">{this.props.infoTxt}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 ">
              <div className="dt_rightBtns">
                <button id="doubleBtn" onClick={() => this.btn_fn("double")} className={(this.props.isMobile) ? "betBtnAlign dealBtn dr_bt_prev cursor_none" : "newGameBtn dealBtn dr_bt_prev"}
                  onPointerDown={(e) => {
                    e.preventDefault();
                    document.getElementById("doubleBtn").style.transform = "scale(0.85)";
                  }}
                  onPointerOut={(e) => {
                    e.preventDefault();
                    document.getElementById("doubleBtn").style.transform = "scale(1)";
                  }}
                  onPointerUp={(e) => {
                    e.preventDefault();
                    document.getElementById("doubleBtn").style.transform = "scale(1)";
                  }}
                >
                  <span className="undoBtnTxt">{this.props.language.Double}</span>
                </button>

                <button
                  id="dealBtn"
                  onClick={(e) => {
                    e.preventDefault();
                    this.btn_fn("deal")
                  }
                  }
                  className={(this.props.isMobile) ? "cursor_none betBtnAlign dealBtn dr_bt_1 disBtn" : "betBtnAlign dealBtn dr_bt_1 disBtn"}
                  onPointerDown={(e) => {
                    e.preventDefault();
                    document.getElementById("dealBtn").style.transform = "scale(0.85)";
                  }}
                  onPointerOut={(e) => {
                    e.preventDefault();
                    document.getElementById("dealBtn").style.transform = "scale(1)";
                  }}
                  onPointerUp={(e) => {
                    e.preventDefault();
                    document.getElementById("dealBtn").style.transform = "scale(1)";
                  }}
                >
                  <span id="betBtnTxt" className="undoBtnTxt">{this.props.betBtnTxt}</span>
                </button>
              </div>
            </div>
          </div>
          <div className="dt_timer_Sec" id="timer">
            <div id="dt_timerGlow"></div>
            <span className="dt_time_txt" id="dt_timeSpan"> {this.state.timeTxt} </span>
          </div>
        </div>
      </div >
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    language: state.languageObjs.languageObj
  }
}
export default connect(mapStatesToProps)(GameBox)